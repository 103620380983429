<div class="text-center">
  <div class="mt-5 mb-3">
    <a href="#">
      <img
        *ngIf="!isSchool24"
        src="../../../../../../../assets/brand/os/logo/dark.svg"
        alt="OnlineSlagen logo"
      />
      <img
        *ngIf="isSchool24"
        src="../../../../../../../assets/brand/s24/logo/dark.svg"
        alt="School24 logo"
        width="160"
        height="50"
      />
    </a>
  </div>
  <div class="col-12 col-xl-5 mx-auto">
    <shared-title
      class="d-block mb-2"
      [title]="'Geen toegang'"
    ></shared-title>
    <p>
      Je hebt helaas geen toegang. Koop de training via
      <a
        href="https://www.onlineslagen.nl"
        target="blank"
        class="os-link"
      >
        www.onlineslagen.nl
      </a>
      .
    </p>
  </div>

  <div class="mt-2">
    <img
      src="../../../../assets/no_access.svg"
      class="col-12 col-md-5"
    />
  </div>
</div>
