<shared-navbar></shared-navbar>
<div class="os-page-background scroll-padding pt-3 pt-xl-4">
  <h1 class="mb-3 mt-5 text-center col-12">Niet gevonden</h1>
  <h5 class="mb-3 text-center col-12 os-subtitle">Helaas! Deze pagina bestaat niet.</h5>
  <div class="col-12 text-center">
    <shared-button
      [text]="'Contactpagina'"
      (click)="goToContact()"
    ></shared-button>
  </div>
  <div class="justify-content-center d-flex">
    <img
      src="../../../../assets/page_not_found.svg"
      class="col-12 col-md-5"
    />
  </div>
</div>

<chat-component [userExtended]="userExtended"></chat-component>
