import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@app/auth/auth.service";
import { UtilService } from "@app/shared/services/util.service";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "@app/shared/basic-components";

@Component({
  standalone: true,
  selector: "error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
  imports: [CommonModule, ButtonComponent],
})
export class ErrorComponent implements OnInit, OnChanges {
  @Input() errorResponse!: HttpErrorResponse;

  errorTitle!: string;
  errorSubtitle!: string;
  imagePath!: string;
  isSchool24!: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.checkError();
    this.isSchool24 = UtilService.isSchool24();
  }

  checkError() {
    if (this.errorResponse) {
      this.checkErrorStatusAndSetError(this.errorResponse);
    }
  }

  checkErrorStatusAndSetError(error: HttpErrorResponse) {
    if (error.status == 404) {
      this.setStateError("Niet gevonden", "Helaas deze pagina bestaat niet :(");
      this.imagePath = "../../../../assets/error_images/page_not_found.svg";
    } else if (error.status == 401) {
      const isAuthenticated = this.authService.isAuthenticated();
      if (!isAuthenticated) {
        this.router.navigate(["auth"]);
      } else {
        this.setStateError("Helaas!", "Je hebt geen toegang");
        this.imagePath = "../../../../assets/error_images/no_access.svg";
      }
    } else if (error.status == 500) {
      this.setStateError("Interne server fout", "Controleer je verbinding of neem contact met ons op");
      this.imagePath = "../../../../assets/error_images/server_down.svg";
    } else {
      this.setStateError("Er ging iets fout", "Controleer je verbinding en URL of neem contact met ons op");
      this.imagePath = "../../../../assets/error_images/general_error.svg";
    }
  }

  goToContact() {
    if (UtilService.isSchool24()) {
      window.open("https://www.school24.nl/contact", "_blank");
    } else {
      window.open("https://www.onlineslagen.nl/contact", "_blank");
    }
  }

  goToPreviousPage() {
    window.history.back();
  }

  setStateError(errorTitle: string, errorSubtitle: string) {
    this.errorTitle = errorTitle;
    this.errorSubtitle = errorSubtitle;
  }

  ngOnChanges() {
    this.checkError();
  }
}
